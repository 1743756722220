<template>
  <div id="about">
    <div class="top_img"><img src="@/assets/img/about_01.png" alt="" /></div>
    <p class="title">了解孤云网</p>
    <div class="content">
      <div class="line line1"></div>
      <div class="line line2"></div>
      <div class="line line3"></div>
      <div class="line line4"></div>
      <span>中山市天牛网络有限公司</span>是一家以服务为主的电子商务平台。<br />
      平台致力于为企业提供优质电商发展解决方案和运营服务，依托深厚的研发和运营技术，
      平台积累了丰富的店铺运营、社会化营销和互联网商业服务经验，得到业界的一致认可。<br /><br /><br />
      <span>孤云网</span>平台，是采购与发货一站式服务的专业礼品代发平台，<br />
      公司大仓储合作，快递费全国最低，多种礼品选择，自由配量、配重，100%安全，
      批量下单、发货，轻松搞定发货，管理方便专业仓储，专人售后对接，售后有保障。
    </div>
    <div class="img_list">
      <div>
        <img src="@/assets/img/about_02.png" alt="" />
        3年运营经验
      </div>
      <div>
        <img src="@/assets/img/about_03.png" alt="" />
        服务客户超过30万
      </div>
      <div>
        <img src="@/assets/img/about_04.png" alt="" />
        省市地区覆盖过百
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {};
  },
  created() {},
  mounted() {},
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
#about {
  .top_img {
    width: 100%;
    img {
      width: 100%;
      display: block;
    }
  }
  .title {
    font-size: 36px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
    margin-top: 40px;
    margin-bottom: 40px;
    text-align: center;
  }
  .content {
    position: relative;
    font-size: 16px;
    color: #999999;
    text-align: center;
    width: 650px;
    margin: 0 auto 50px;
    span {
      color: #ff8800;
      font-weight: bold;
    }
    .line {
      position: absolute;
      width: 30px;
      height: 30px;
    }
    .line1 {
      top: -19px;
      left: -19px;
      border-top: 2px solid #ea242f;
      border-left: 2px solid #ea242f;
    }
    .line2 {
      top: -19px;
      right: -19px;
      border-top: 2px solid #ea242f;
      border-right: 2px solid #ea242f;
    }
    .line3 {
      bottom: -19px;
      left: -19px;
      border-bottom: 2px solid #ea242f;
      border-left: 2px solid #ea242f;
    }
    .line4 {
      bottom: -19px;
      right: -19px;
      border-bottom: 2px solid #ea242f;
      border-right: 2px solid #ea242f;
    }
  }
  .img_list {
    text-align: center;
    div {
      display: inline-block;
      &:not(:first-child) {
        margin-left: 90px;
      }
    }
    font-size: 16px;
    font-family: Microsoft YaHei;
    color: #666666;

    img {
      height: 113px;
      display: block;
    }
  }
  .bg {
    margin-top: 70px;
    background: #f5f5f5;
    overflow: hidden;
    padding-bottom: 70px;
    .title1 {
      font-size: 36px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #333333;
      margin-top: 70px;
      margin-bottom: 50px;
      text-align: center;
      opacity: 1;
    }
    .img_list1 {
      text-align: center;
      div {
        display: inline-block;
        text-align: left;
        margin-left: 32px;
        img {
          height: 110px;
          display: block;
          margin: auto;
        }
        p {
          &:first-of-type {
            text-align: center;
            font-size: 22px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #666666;
            margin-top: 20px;
            margin-bottom: 10px;
          }
          &:last-of-type {
            width: 224px;
            font-size: 14px;
            font-family: Microsoft YaHei;
            color: #999999;
          }
        }
      }
    }
  }
  .content2 {
    overflow: hidden;
    padding-bottom: 70px;
    border: 5px solid #eee;
    .title {
      margin-top: 70px;
      margin-bottom: 50px;
    }
    .map {
      display: flex;
      justify-content: center;
      .map_img {
        width: 700px;
        img {
          width: 100%;
          display: block;
        }
      }
      .text_wrap {
        display: flex;
        flex-direction: column;
        border-top: 1px solid #eeeeee;
        border-right: 1px solid #eeeeee;
        border-bottom: 1px solid #eeeeee;
        .title1 {
          font-size: 28px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #ff8800;
          margin-top: 56px;
          margin-bottom: 55px;
          text-align: center;
        }
        div {
          display: flex;
          align-items: center;
          flex: 1;
          border-top: 1px solid #eee;
          padding-left: 35px;
          padding-right: 23px;
          &:nth-child(even) {
            background: #eeeeee;
          }
          img {
            width: 30px;
            margin-right: 10px;
          }
          p {
            display: inline-block;
            vertical-align: middle;
          }
        }
      }
    }
  }
}
</style>
